import $ from 'jquery' // 引入jquery 方法

//修改Vuex当前url
export function menuJump(store:any,url:string) {
    store.commit('pageChange',url);
}

//滚动到指定位置
export function scrollPosition(t:number) {
    let top = 0;
    if(t){
        top = t;
    }
    $('html, body').stop().animate({scrollTop: top}, 800);
}