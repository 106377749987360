
import { defineComponent, reactive, toRefs, watch, ref, onMounted, nextTick } from 'vue';

import { EffectFade, Pagination, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

import { useStore } from 'vuex' // 引入useStore 方法
import { CountUp } from 'countup.js' //字体跳动
import { scrollPosition } from '../hooks/scroll-position' //导航跳转

export default defineComponent({
  name: 'HomeView',
  props: ['device'],
  components: {
    Swiper,
    SwiperSlide,
  },
  setup(props) {
    const store = useStore()  // 该方法用于返回store 实例
    let data = reactive({
      vh: 0,
      flag: true,
      offsetTop: 0,
      offsetHeight: 0,
      isHLAni: false,
      erpUrl: "https://seller.exshopee.cn",
      docUrl: "https://exshopee.cn/document.html",
      videoUrl: "https://space.bilibili.com/1687423767",
      imgUrl: "https://exshopee.oss-cn-shenzhen.aliyuncs.com/heiling/",
      banner: [{
        type: 1,
        img: "banner-01",
        name: "上架系统",
        title: "快速铺货神器",
        text: "一键采集搬运国内电商平台热销商品，上架至Shopee虾皮跨境商城中，黑领ERP系统，自动翻译、自动定价，助您快速上手"
      }, {
        type: 2,
        img: "banner-02",
        name: "货代系统",
        title: "无货源一件代发",
        text: "无需自行打包发货，出单后只需将商品采购至我们的地址，我们收到包裹后会进行拆包、验货、合包、贴单、送仓。无时间地点限制，兼职也能玩转跨境电商"
      }],
      NavList: [{
        icon: 'icon-01',
        name: '注册用户',
        count: "999"
      }, {
        icon: 'icon-02',
        name: '授权店铺',
        count: "999"
      }, {
        icon: 'icon-03',
        name: '处理订单',
        count: "999"
      }, {
        icon: 'icon-04',
        name: '采集商品',
        count: "999"
      }, {
        icon: 'icon-05',
        name: '上架商品',
        count: "999"
      }],
      ProductInt: [{
        icon: 'icon-10',
        name: '批量编辑',
        text: "批量采集、批量编辑，方便快捷，快速铺货神器"
      }, {
        icon: 'icon-11',
        name: '自动定价',
        text: "上架后系统会自动添加利润、运费，手续费"
      }, {
        icon: 'icon-12',
        name: '文字翻译',
        text: "中文编辑，发布后自动翻译小语种"
      }, {
        icon: 'icon-13',
        name: '图片翻译',
        text: "支持多国语言翻译"
      }, {
        icon: 'icon-14',
        name: '图片美化',
        text: "内置图片美化工具，一键编辑保存，无需导入导出"
      }, {
        icon: 'icon-15',
        name: '上架折扣',
        text: "发布到站点自动折扣，无需前往营销中心二次设置"
      }],
      LogInt: [{
        img: "image-04",
        icon: 'icon-21',
        name: '拆包验货',
        text: "每个包裹我们都会认真核对款式、数量、颜色是否与订单一致"
      }, {
        img: "image-05",
        icon: 'icon-22',
        name: '包裹拍照',
        text: "打包过程中，我们会进行产品、包裹和称重图的拍摄与上传，用户可直接在订单中查看"
      }, {
        img: "image-06",
        icon: 'icon-23',
        name: '距离接近',
        text: "距离Shopee中转仓很近，早中晚三次送仓，当天抵达的包裹 当天送仓"
      }, {
        img: "image-07",
        icon: 'icon-24',
        name: '24小时在线',
        text: "专属打包群，多对一服务，微信、QQ、手机24小时在线，若发现包裹出现问题，第一时间与您联系"
      }, {
        img: "image-08",
        icon: 'icon-25',
        name: '价格优惠',
        text: "针对不同的商品与客户制定多钟打包标准，费用低至2元/单，充值金额越多优惠力度越大"
      }, {
        img: "image-09",
        icon: 'icon-26',
        name: '服务保障',
        text: "如存在包裹丢件，发错包裹等情况，将获得全额赔付"
      }],
      ProductProcess: [{
        icon: 'icon-06',
        name: '安装插件'
      }, {
        icon: 'icon-07',
        name: '采集商品'
      }, {
        icon: 'icon-08',
        name: 'ERP编辑'
      }, {
        icon: 'icon-09',
        name: '发布Shopee店铺'
      }],
      LogProcess: [{
        icon: 'icon-16',
        name: 'Shopee出单'
      }, {
        icon: 'icon-17',
        name: '国内快递派送'
      }, {
        icon: 'icon-18',
        name: '打包验货贴单'
      }, {
        icon: 'icon-19',
        name: '送往Shopee仓库'
      }, {
        icon: 'icon-20',
        name: '国际运输至买家'
      }],
      college: [{
        icon: 'icon-27',
        name: '完全免费'
      }, {
        icon: 'icon-28',
        name: '实操干货'
      }, {
        icon: 'icon-18',
        name: '模块分段'
      }, {
        icon: 'icon-29',
        name: '通俗易懂'
      }],
      CoopInt: [{
        icon: 'icon-30',
        name: '推广返利',
        text: "邀请用户注册，获取永久现金返利"
      }, {
        icon: 'icon-31',
        name: '股权投资',
        text: "携手并进，共创未来"
      }, {
        icon: 'icon-02',
        name: '分仓合作',
        text: "义乌、泉州开仓合作"
      }],
      problem: [{
        title: "Shopee虾皮绿色通道入驻？",
        text: "绿色通道入驻审核时间更快，最快当天过审，如需绿色通道入驻可扫描底部“虾皮绿色通道入驻二维码”或联系我们获取入驻链接"
      }, {
        title: "出单后如何发货？",
        text: "只需要使用黑领ERP货代系统，将订单商品发送到我们仓库，我们会拆包、检验，组装后贴上国际面单，送到shopee中转仓，再由中转仓交付给买家。"
      }, {
        title: "入驻Shopee多少钱？",
        text: "如果你有营业执照和电商运营数据，直接官网注册是不需要一分钱的，如果以上资料都没有，可以直接找淘宝代入驻包下店，价格不会超过300元。"
      }, {
        title: "不会英语怎么办？",
        text: "不会英语也没关系，Shopee后台界面全中文操作，客户发送的聊天文字也会自动翻译成中文。"
      }, {
        title: "一个执照主体能开多少个店铺？",
        text: "一个执照可以开很多店铺，每个国家可以开3个，关于如何开通可以联系企业微信群经理。"
      }, {
        title: "Shopee虾皮回款？",
        text: "虾皮回款是美元等非RMB货币，所以需要绑定连连等第三方收款平台，在注册成功shopee后在后台可以直接注册绑定，回款时间为一周一次。"
      }, {
        title: "商品如何定价？",
        text: "注册成功后，我们会有1:1的对接服务，会免费赠送定价工具，通过erp上架也可以自动定价，无需自行计算运费手续费。"
      }, {
        title: "如何使用黑领ERP？",
        text: "1、注册成为黑领的会员，2、登录后绑定店铺，3、出单后黑领会自动同步您的订单，4、点击待打包将订单商品发往我们仓库，填上物流单号即可。"
      }, {
        title: "如何快速学习运营技巧？",
        text: "可以进入我们的QQ群，关于shopee的所以问题都可以交流（924554826）。"
      }],
      partner: [{
        active: false,
        icon: "partner-01",
        title: "shopee",
        url: "https://shopee.cn"
      }, {
        active: false,
        icon: "partner-02",
        title: "shopee",
        url: "https://global.lianlianpay.com/register?invitecode=VBAKPQ41FI"
      }, {
        active: false,
        icon: "partner-03",
        title: "shopee",
        url: "http://share.payoneer.com/nav/YVaguFMtoAMp885Q9SxY0hjw2oAtsCzxdasz34iAsL-RyudS6a6GfhdUq0OFxEdE3lD6Jqkegg3klnL4v4ilfg2"
      }, {
        active: false,
        icon: "partner-04",
        title: "shopee",
        url: "https://www.socketpro.link/aff/83590"
      }, {
        active: false,
        icon: "partner-05",
        title: "shopee",
        url: "https://translate.google.cn"
      }, {
        active: false,
        icon: "partner-06",
        title: "shopee",
        url: "https://www.cifnews.com"
      }]
    })

    function clickButton(etype: number) {
      if (etype == 1) {
        window.open(data.erpUrl);
      } else if (etype == 2) {
        window.open(data.docUrl);
      } else if (etype == 3) {
        console.log(1)
      } else if (etype == 4) {
        window.open(data.videoUrl);
      }
    }

    //状态切换
    function trigger(ix: number, state: boolean) {
      data.partner[ix].active = state;
    }

    //页面跳转
    function pageJump(url: string) {
      window.open(url);
    }

    //Swiper相关
    const modules = [EffectFade, Pagination, Autoplay]
    const onSwiper = (swiper: any) => {
      console.log(swiper);
    }

    //相关问题
    var viewbox = 4;
    if (props.device == "mobile") {
      viewbox = 1;
    }

    const heiling = ref<any>(null);
    onMounted(() => {
      data.offsetTop = heiling.value.offsetTop;
      data.offsetHeight = heiling.value.offsetHeight;
      initCountUp(); //触发数字跳动
    })

    //监听滚动条事件
    watch(() => store.state.scrollTop, (newValue: number, oldValue: number) => {
      if (store.state.scrollTop > (data.offsetTop * 0.3) && store.state.scrollTop < (data.offsetTop + data.offsetHeight * 0.5)) {
        data.isHLAni = true;  //黑领时代模块动画
      }
    })

    //监听点击导航事件
    const mainBox = ref<any>(null);
    watch(() => store.state.page, (newValue:string, oldValue:string) => {
      let domArr = [...mainBox.value.children]; //获取所以子dom
      if(newValue === 'homepage'){
        scrollPosition(0); //滚动到顶部
      }else if(newValue === 'about'){
        scrollPosition(document.body.offsetHeight); //滚动到底部
      }else{
        for(let i in domArr){
          if(domArr[i].id == newValue){
            scrollPosition(domArr[i].offsetTop); //定位到指定dom的位置
          }
        }
      }
    })

    //字体跳动
    const countupRef = ref<any>([]);
    function initCountUp() {
      nextTick(() => {
        const countupLength = countupRef.value.length
        let animal = null;
        let doms = [...countupRef.value];
        for (let i = 0; i < doms.length; i++) {
          animal = new CountUp(
            doms[i],
            doms[i].innerText
          )
          animal.start()
        }
      })
    }


    return {
      ...toRefs(data),
      ...toRefs(props),
      clickButton,
      trigger,
      pageJump,
      modules,
      onSwiper,
      viewbox,
      heiling,
      countupRef,
      mainBox
    }
  }
});
