
import Header from '@/components/HeaderView.vue';
import Footer from '@/components/FooterView.vue';
import { defineComponent, reactive, toRefs } from 'vue'
import { useStore } from 'vuex' // 引入useStore 方法
import { menuJump } from './hooks/scroll-position'

export default defineComponent({
  components: {
    Header,
    Footer
  },
  setup() {
    const store = useStore()
    let data = reactive({
      device: "pc",
      menuLeft: '-100%',
      boxLeft: "0",
      menuBar: [{
        active: true,
        name: "首页",
        value: "homepage"
      }, {
        active: false,
        name: "上架",
        value: "product"
      }, {
        active: false,
        name: "货代",
        value: "logistics"
      }, {
        active: false,
        name: "黑领学院",
        value: "college"
      }, {
        active: false,
        name: "战略合作",
        value: "cooperation"
      }, {
        active: false,
        name: "常见问题",
        value: "problem"
      }, {
        active: false,
        name: "关于我们",
        value: "about"
      }]
    })

    //设备类型
    if ((navigator.userAgent.match(/(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i))) {
      data.device = "mobile"
    }else{
      data.device = "pc"
    }

    //节流
    let pass = true;
    let throttle = ()=>{
      if(pass){
        pass = false;
        setTimeout(()=>{
          deviceDetect();
          pass = true;
        },500)
      }
    }

    //根据尺寸修改设备
    function deviceDetect(){
      let winw = document.body.clientWidth;
      if(winw < 950){
        data.device = "mobile"
      }else{
        data.device = "pc"
      }
    }
    deviceDetect();
    window.addEventListener('resize',throttle);

    function menuBarCutover(res: any) {
      if (res.menuState) {
        data.menuLeft = "0";
        data.boxLeft = "100%";
      } else {
        data.menuLeft = "-100%";
        data.boxLeft = "0";
      }
    }
    
    return { ...toRefs(data), store, menuBarCutover, menuJump }
  }
})
