
import { defineComponent, reactive, toRefs } from 'vue'
import { useStore } from 'vuex' // 引入useStore 方法
import { scrollPosition, menuJump } from '../hooks/scroll-position'

export default defineComponent({
    props: ['device', 'menuBar', 'boxLeft'],
    emits: ['menuBarCutover'],
    setup(props, context) {
        const store = useStore()
        let data = reactive({
            isOpacity: 0,
            noTop: true,
            docUrl: "https://seller.exshopee.cn/"
        })

        function menuBarTrigger(state: boolean) {
            context.emit('menuBarCutover', { menuState: state });
        }

        function clickButton() {
            window.open(data.docUrl);
        }

        //浏览器滚动
        let pass = true;
        window.onscroll = ()=>{
            if(pass){
                pass = false;
                setTimeout(() => {
                    var scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
                    //头部菜单
                    if(scrollTop == 0){
                        data.noTop = true;
                        data.isOpacity = 0;
                    }else{
                        data.noTop = false;
                        data.isOpacity = 1;
                    }
                    pass = true;
                    store.commit('modTop',scrollTop)
                }, 200);
            }
        }

        return {
            ...toRefs(data),
            ...toRefs(props),
            menuBarTrigger,
            clickButton,
            scrollPosition,
            menuJump,
            store
        }
    }
})
