import { createStore } from 'vuex'

export default createStore({
  state: {
    scrollTop:0, //滚动条位置
    page:'homepage' //菜单位置
  },
  getters: {
  },
  mutations: {
    modTop (state,value) {
      state.scrollTop = value;
    },
    pageChange (state,value) {
      state.page = value;
    }
  },
  actions: {
  },
  modules: {
  }
})
